<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		value?: number;
		maxValue?: number;
	}>(),
	{
		value: 0,
		maxValue: 100
	}
);
const backgroundPosition = computed(() => `-${100 - (props.value * 100) / props.maxValue}%`);

const textValue = computed(() => `${numberFormat(props.value)}/${numberFormat(props.maxValue)}`);
</script>

<template>
	<div class="cosmic-boxes-progress">
		<NuxtIcon name="20/entries" filled class="coin" />
		<div class="box">
			<div class="progress">
				<div class="overlay-progress">
					<div class="progress-value" />
					<AText class="text-value color-neutral-5" :modifiers="['bold']" as="div">
						{{ textValue }}
					</AText>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.cosmic-boxes-progress {
	position: relative;
	width: 100%;
	padding-left: 10px;

	.box {
		background: var(--gradient-1);
		width: 100%;
		height: 28px;
		border-radius: 28px;
		padding: 2px;
	}

	.progress {
		background: var(--neutral-100);
		border-radius: 24px;
		width: 100%;
		height: 100%;
		padding: 2px;
	}

	.overlay-progress {
		position: relative;
		overflow: hidden;
		border-radius: 20px;
		width: 100%;
		height: 100%;
	}

	.progress-value {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 20px;
		transform: translateX(v-bind(backgroundPosition));
		background: var(--gradient-14);
	}

	.text-value {
		position: absolute;
		right: 2px;
		top: 0;
		z-index: 2;
		line-height: 20px;
	}

	.coin {
		position: absolute;
		display: inline-flex;
		z-index: 3;
		font-size: 30px;
		top: calc(50% - 15px);
		left: -1px;
	}
}
</style>
